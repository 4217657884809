'use strict';

export class FetchError extends Error {
  constructor(message) {
    super(message);
    this.name = "FetchError";
  }
}

export function fetchJSON(url, options, tries=2, backoff=1000) {
  function wait(delay) {
    return new Promise(res => setTimeout(res, delay));
  }

  function onError(err) {
    if (!tries) {
      const msg = `Failed to make request to ${url}`;
      console.error(msg);
      throw new FetchError(msg);
    }
    return wait(backoff).then(() => fetchJSON(url, options, tries-1, backoff*2));
  }

  return fetch(url, options).then(res => {
    // Don't check status of response, 'bad' responses could still be 'valid'
    return res.json();
  }).catch(onError);
}
