"use strict";

export function show_banner(response, sticky = false) {
  const banner_el = document.querySelector('.banner');
  var message = response.message;
  var message_type = `alert-${response.type || 'info'}`;

  // If this request is not sticky and and a sticky banner is already visible then ignore this one
  if (!sticky && banner_el.classList.contains('banner--sticky')) {
    return;
  }

  const rm = [...banner_el.classList].filter((el) => el.startsWith('alert-'));
  banner_el.classList.remove('d-none', 'banner--sticky', ...rm);
  banner_el.classList.add(message_type);

  if (sticky) {
    banner_el.classList.add('banner--sticky');
  }

  banner_el.textContent = message;
}

export function hide_banner() {
  const banner_el = document.querySelector('.banner:not(.banner--sticky)');
  banner_el.classList.add('d-none');
}
